import request from './base/request';
import store from '@/store';

const DASHBOARD_BRANCH_SERVICE_URL = '/dashboard/list-branch';
const DASHBOARD_FILTER_ENDPOINT = '/dashboard/get-filter'

const getDashboardBranch = (data, options = {}) => {
  return request
    .get(DASHBOARD_BRANCH_SERVICE_URL + '?contractor_id=' + data, { headers: options })
    .then((res) => {
      // store.dispatch('branch/setListBranch', res);
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const getDashboardFilter = (contractorId) => {
  return request
    .get(DASHBOARD_FILTER_ENDPOINT, {
      params: {
        contractor_id: contractorId
      }
    })
    .then((res) => {
      let { organizational_division, countries, company_name, business_name } = res

      organizational_division = (organizational_division || []).map(item => {
        return {
          name: item.type,
          value: item.id
        }
      })
      organizational_division = [{
        name: 'すべて',
        value: '',
      }, ...organizational_division]

      countries = (countries || []).map(item => {
        return {
          name: item,
          value: item,
        }
      })
      countries = [{
        name: 'すべて',
        value: '',
      }, ...countries]

      company_name = (company_name || []).map(item => {
        return {
          name: item,
          value: item,
        }
      })
      company_name = [{
        name: 'すべて',
        value: '',
      }, ...company_name]

      business_name = (business_name || []).map(item => {
        return {
          name: item,
          value: item,
        }
      })
      business_name = [{
        name: 'すべて',
        value: '',
      }, ...business_name]

      return {
        organizational_division,
        countries,
        company_name,
        business_name
      }
    })
    .catch((error) => {
      throw error;
    });
}

const getListBranchLayerVisible = () => {
  return request
    .get('/branch-attribute')
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export { getDashboardBranch, getDashboardFilter, getListBranchLayerVisible };
